var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',{staticClass:"clapans"},[_c('CCardHeader',[(_vm.alert_message)?[_vm._v(" Общая сумма: "),_c('b',[_vm._v(_vm._s(_vm.all_sum_wallet))]),_c('br'),_vm._v(" Общий количество: "),_c('b',[_vm._v(_vm._s(_vm.all_sum_counter))]),_c('br'),_vm._v(" Общий вес: "),_c('b',[_vm._v(_vm._s(_vm.all_sum_weight))])]:_vm._e()],2),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","column-filter":"","table-filter":"","sorter":"","tableFilter":{'label':'Поиск','placeholder':'Введите слово для поиска'}},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('div',{staticStyle:{"display":"flex"}},[_c('a',{staticClass:"btn btn-success",staticStyle:{"margin-right":"5px"},attrs:{"target":"_blank","href":'https://api.whatsapp.com/send?phone='+item.phone.phone1+'&text=Уважаемый клиент Tek-Kargo! \n'+
'Вам пришел груз. Код клиента: '+item.kod+',\n'+
'Дата отправления: '+item.data+',\n'+
'Количество мест: '+item.counter_place+',\n'+
'Вес: '+item.weight+',\n'+
'Сумма к оплате: '+item.summa+'.\n'+
'\n'}},[_vm._v(" Отправить Whatsapp ")]),(item.phone.phone2!=null)?_c('a',{staticClass:"btn btn-success",staticStyle:{"margin-right":"5px"},attrs:{"target":"_blank","href":'https://api.whatsapp.com/send?phone='+item.phone.phone2+'&text=Уважаемый клиент Tek-Kargo! \n'+
'Вам пришел груз. Код клиента: '+item.kod+',\n'+
'Дата отправления: '+item.data+',\n'+
'Количество мест: '+item.counter_place+',\n'+
'Вес: '+item.weight+',\n'+
'Сумма к оплате: '+item.summa+'.\n'+
'\n'}},[_vm._v(" Отправить Whatsapp ")]):_vm._e()])])}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }