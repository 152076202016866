<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          <template v-if="alert_message">
            Общая сумма: <b>{{ all_sum_wallet }}</b><br>
            Общий количество: <b>{{ all_sum_counter }}</b><br>
            Общий вес: <b>{{ all_sum_weight }}</b>
          </template>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >


            <td slot="action" slot-scope="{ item,index }">
              <div style="display: flex">
                <a class="btn btn-success" style="margin-right: 5px;"
                   target="_blank"
                   :href="'https://api.whatsapp.com/send?phone='+item.phone.phone1+'&text=Уважаемый клиент Tek-Kargo! \n'+
'Вам пришел груз. Код клиента: '+item.kod+',\n'+
'Дата отправления: '+item.data+',\n'+
'Количество мест: '+item.counter_place+',\n'+
'Вес: '+item.weight+',\n'+
'Сумма к оплате: '+item.summa+'.\n'+
'\n'">
                  Отправить Whatsapp
                </a>
                <a class="btn btn-success" style="margin-right: 5px;" v-if="item.phone.phone2!=null"
                   target="_blank"
                   :href="'https://api.whatsapp.com/send?phone='+item.phone.phone2+'&text=Уважаемый клиент Tek-Kargo! \n'+
'Вам пришел груз. Код клиента: '+item.kod+',\n'+
'Дата отправления: '+item.data+',\n'+
'Количество мест: '+item.counter_place+',\n'+
'Вес: '+item.weight+',\n'+
'Сумма к оплате: '+item.summa+'.\n'+
'\n'">
                  Отправить Whatsapp
                </a>
              </div>
            </td>
          </CDataTable>

        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum_wallet: '',
      all_sum_counter: '',
      all_sum_weight: '',
      file_type: '',
      file_city: '',
      race_number: '',
      warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      file_path: '',
      fileData: '',
      variables: '',
      tableItems: [],
      tableFields: [
        {key: "data", label: "Дата"},
        {key: "brk", label: "БРК "},
        {key: "ves", label: "Вес", _classes: "text-center"},
        {key: "kod", label: "Код"},
        {key: "fio", label: "ФИО"},
        {key: "naim", label: "Наим"},
        {key: "upk", label: "УПК"},
        {key: "file_city", label: "Адрес"},
        {key: "file_type", label: "Тип рейса"},
        {key: "race_number", label: "Номер рейса"},
        {key: "price_per_kg", label: "цена за кг"},
        {key: "price_per_place", label: "цена за место"},
        {key: "counter_place", label: "Колво мест"},
        {key: "percent", label: "Процент"},
        {key: "oplata_stambula", label: "Оплата Стамбула"},
        {key: "summa", label: "Сумма"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults() {
      let app = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + window.auth.token;
      axios.get('/auth/downloads/list/' + this.$route.params.id).then(response => {
        let download = response.data.download;
        let arr = JSON.parse(download.content);
        app.tableItems = arr;
        app.all_sum_wallet = 0;
        app.all_sum_counter = 0;
        app.all_sum_weight = 0;
        for (var i = 0; i < app.tableItems.length; i++) {
          app.tableItems[i].action = true;
          if (app.tableItems[i].brk == 0) {
            app.tableItems.splice(i, 1);
          }
          app.all_sum_wallet += app.tableItems[i].summa
          app.all_sum_counter += app.tableItems[i].counter_place
          app.all_sum_weight += app.tableItems[i].ves
        }
      }).catch(error => {
        if (error.response.status == 401) {
          window.auth.logout();
        }
      });
    },
    convertToArray() {
      event.preventDefault();
      console.log('test');
      console.log('test2');
      let app = this;
      app.loader = true;
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      let formData = new FormData();
      formData.append('file', app.file_path);
      formData.append('file_type', app.file_type);
      formData.append('file_city', app.file_city);
      formData.append('race_number', app.race_number);
      axios.post('/auth/convertor/excel-to-json', formData, config).then(function (response) {
        app.success = true;
        console.log(response);
        let arr = response.data.array;
        app.tableItems = arr;
        app.all_sum_wallet = 0;
        app.all_sum_counter = 0;
        app.all_sum_weight = 0;
        for (var i = 0; i < app.tableItems.length; i++) {
          app.tableItems[i].action = true;
          if (app.tableItems[i].brk == 0) {
            app.tableItems.splice(i, 1);
          }
          app.all_sum_wallet += app.tableItems[i].summa
          app.all_sum_counter += app.tableItems[i].counter_place
          app.all_sum_weight += app.tableItems[i].ves
        }
        app.alert_message = 'Данные за парсены';
        setTimeout(function () {
          app.success = false;
        }, 3000)
      }).catch(function (error) {

      })
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
